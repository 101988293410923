import angular from 'angular'
import angularCookies from 'angular-cookies'

export default angular.module('Unoauto', [
        angularCookies,
    ])
    .config([
        '$interpolateProvider',
        '$httpProvider',
        '$logProvider',
        '$locationProvider',
         function ($interpolateProvider, $httpProvider, $logProvider, $locationProvider) {
            // Enable or disable $log
            $logProvider.debugEnabled(true);
            $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

            $interpolateProvider.startSymbol('[[').endSymbol(']]');

             $locationProvider.html5Mode({
                 enabled : true,
                 requireBase: false,
                 rewriteLinks : false
             });
        }
    ])
;
