import $ from 'jquery'
import slick from './slick'

$(document).ready(function(){
    /* HOME */
    // Buscador Home
    $('.buscador > ul li').on('click', function (e) {
        e.preventDefault();
        var pestana = $(this);
        var contenido = pestana.attr('class');
        $('.buscador > ul li, .buscador .pestana').each(function() {
            $(this).removeClass('active');
        });
        pestana.addClass('active');
        $('.buscador .' + contenido.replace(' active', '')).addClass('active');
    });
    /* LISTADO */
    // Filtro Precio Listado
    $('.capa-precio > span').on('click', function (e) {
        e.preventDefault();
        if($('.capa-precio').hasClass('active')){
            $('.capa-precio').removeClass('active');
        } else {
            $('.selectFiltro').each(function() {
                $(this).find('ul').css('display', 'none');
            });
            $('.capa-precio').addClass('active');
        }
    });
    /* GENERAL */
    // Carrusel
    if($('.variable-width').length != 0){
        $('.variable-width').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true
        });
    }
    // Input numerico
    $('.number').keypress(function (e) {
        if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
           return false;
        }
    });
    // Modales
    $('.btn-modal').on('click', function (e) {
        e.preventDefault();
        // Select home, si está disabled no se abre el modal
        if (!($(this).hasClass('selectFiltro') && $(this).hasClass('disabled'))){
            var modal = $(this).attr('data-target');
            if($('#'+modal).hasClass('active')){
                $('#'+modal).removeClass('active');
                // Eliminamos contenido HTML
            } else {
                // Añadimos contenido HTML
                $('#'+modal).addClass('active');
            }
        }
    });
    $('.modal .cerrar').on('click', function (e) {
        e.preventDefault();
        $(this).parents().find('.modal').removeClass('active');
        // Eliminamos contenido HTML
    });
    $('.modal').on('click', function (e) {
        if($(e.target).hasClass('active')) {
            $('.modal').removeClass('active');
            // Eliminamos contenido HTML
        }
    });
    // Select
    $('.selectFiltro').on('click', function (e) {
        var selectActual = $(this).find('ul');
        if (selectActual.css('display') == 'block'){
            if(!$(e.target).hasClass('inputsearch')){
                selectActual.hide();
            }
        } else {
            $('.selectFiltro').each(function() {
                $(this).find('ul').css('display', 'none');
            });
            if (!(selectActual.parents().hasClass('capa-precio'))){
                $('.capa-precio').removeClass('active');
            }
            selectActual.show();
        }
    });
    // Enlaces-seo
    $('.enlaces-seo .ver-mas').on('click', function (e) {
        e.preventDefault();
        var enlace = $(this);
        enlace.parents('.enlaces-seo').find('li').each(function() {
            $(this).show();
        });
        enlace.hide();
    });
    /* FICHA */
    // Desplegables de versión
    $('.ficha .desplegable h3').on('click', function (e) {
        e.preventDefault();
        var elemento = $(this).parent();
        if (elemento.hasClass('abierto')){
            elemento.removeClass('abierto');
        } else {
            elemento.addClass('abierto');
        }
    });
    // Desplegar formulario de contacto en ficha y esconderlo
    $(window).on('click', function(e) {
        if((!$(e.target).parents().hasClass('col-der')) && ($('body').hasClass('ficha'))) {
            $('.col-der .mostrar').hide('slow');
            $('.col-der .resumen-precio').show('slow');
        }
        if((!$(e.target).parents().hasClass('info'))
            && (!$(e.target).hasClass('icon-info'))
            && ($('body').hasClass('ficha'))){
            $('.ficha .info').hide();
        }
        if(!$(e.target).parents().hasClass('selectFiltro')){
            $('.selectFiltro ul').each(function(){
                $(this).css('display', 'none');
            });
        }
    });
    $('.ficha .col-der .datos > div > .form-group, .ficha .col-der .datos .btn').on('click', function (e) {
        $('.col-der .mostrar').show('slow');
        $('.col-der .resumen-precio').hide('slow');
    });
    $('.ficha .icon-info').on('click', function (e) {
        if ($(this).parents().hasClass('col-der')){
            $('.ficha .col-der .info').toggle();
        } else {
            if ($(this).parents().hasClass('resumen-precio-oferta')){
                $('.ficha .resumen-precio-oferta .info').toggle();
            } else {
                $('.ficha .encabezado .info').toggle();
            }
        }

    });
    // Capa Fixed
    $.fn.scrollBottom = function() {
        return $(document).height() - this.scrollTop() - this.height();
    };
    $(window).scroll(function (e) {
        e.preventDefault();
        if ($('.col-izq').length
        && !$('.ficha').hasClass('vendido')
        && $('.col-izq').position()
        && $(this).scrollTop() > ($('.col-izq').position().top + 50)){
            $('.col-der .moving').removeClass('bottom').addClass('fixed');
            if ($(this).scrollBottom() < 1) {
                $('.col-der .moving').addClass('bottom');
            }
        } else {
            $('.col-der .moving').removeClass('fixed bottom');
        }
    });
    // Desplegables de contactar ipad
    $('.ipad .flecha, .ipad .btn').on('click', function () {
        if ($('.ipad').hasClass('plegado')){
            $('.ipad').removeClass('plegado');
        } else {
            if($(this).hasClass('flecha')){
                $('.ipad').addClass('plegado');
            }
        }
    });
    // Navegacion con el teclado en carrusel
    $(document).keydown(function (e) {
        if (e.keyCode == 37) {
            $('.slick-prev').click();
        }
        if (e.keyCode == 39) {
            $('.slick-next').click();
        }
    });
    /* ESTATICAS */
    // Anclas en Faq con scroll lento
    $('.faq a').click(function () {
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top
        }, 1000);
    });
    // Flecha cabecera en guías
    $('.guia .icon-flecha-abajo.bajar').on('click', function (e) {
        var bloque = $('.guia .detalle');
        if ($('.guia').hasClass('home')){
            bloque = $('.guia .bloque');
        }
        $('html, body').animate({
            scrollTop: bloque.offset().top
        }, 1000);
    });

    /* Botones + Enter */
    $(document).keypress(function (e) {
        if ($('body').hasClass('ficha') && (e.which == 13)){
            $('.ficha .datos .btn').click();
        } else if ($('body').hasClass('home') && (e.which == 13)){
            $('.home .pestana .btn').click();
        }
    });

});

