import angular from 'angular'
import Routing from './../../routing'
import $ from 'jquery'

const SearchCtrl = ($scope, $http, $window, $rootScope, $log) => {
        $scope.data = {
            aggs: {},
            filtros: {},
            ignores: [],
            totalTemp: 0,
            router: null
        };

        $scope.dataPrice = true;
        $scope.selectedFilters = {};
        $scope.yearStart = null;
        $scope.yearEnd   = null;
        $scope.buildUrls = false;
        $scope.moreFilters = true;
        $scope.checkAggregations = true;
        $scope.showHits  = false;
        $scope.industrialError = false;
        $scope.existSector = false;
        $scope.numRequests = 0;
        $scope.isStock     = false;
        $scope.reload = true;
        $scope.activeFilter = null;
        /**
         * Init
         *
         * @param filters
         */
        $scope.init = function(filters, reload) {

            if (undefined != reload) {
                $scope.reload = reload;
            }

            angular.forEach(filters, function(e, k) {
                var label = e;

                if (e.hasOwnProperty('slug')) {
                    $scope.data.filtros[k] = e.slug;
                    label = e.name;
                } else {
                    $scope.data.filtros[k] = e;
                }

                $scope.initSelectedFilter(k, null, null);
                $scope.selectedFilters[k].label = ucFirst(label);
                if ('place' == k) {
                    $scope.selectedFilters['place'].label += ' plazas';
                }
            });

            $scope.initAggregation(false);
        };

        /**
         * Inicializa todos los filtros que tenga esa página
         *
         * @param filterName
         * @param labelDefault
         */
        $scope.initSelectedFilter = function (filterName, labelDefault) {
            if (!$scope.selectedFilters.hasOwnProperty(filterName)) {
                $scope.selectedFilters[filterName] = {
                    label :  labelDefault,
                    labelDefault: labelDefault,
                    loading: false,
                    data:    {}
                };
            }else {
                $scope.selectedFilters[filterName].labelDefault = labelDefault;
            }

            if (filterName == 'priceFrom' &&  $scope.selectedFilters['priceFrom'].label != $scope.selectedFilters['priceFrom'].labelDefault) {
                $scope.selectedFilters['priceFrom'].label = (parseInt($scope.selectedFilters['priceFrom'].label)).formatMoney(0, ',', '.');
            }

            if (filterName == 'priceUpTo' &&  $scope.selectedFilters['priceUpTo'].label != $scope.selectedFilters['priceUpTo'].labelDefault) {
                $scope.selectedFilters['priceUpTo'].label = (parseInt($scope.selectedFilters['priceUpTo'].label)).formatMoney(0, ',', '.');
            }

            if (filterName == 'feeFrom' &&  $scope.selectedFilters['feeFrom'].label != $scope.selectedFilters['feeFrom'].labelDefault) {
                $scope.selectedFilters['feeFrom'].label = (parseInt($scope.selectedFilters['feeFrom'].label)).formatMoney(0, ',', '.');
            }

            if (filterName == 'feeUpTo' &&  $scope.selectedFilters['feeUpTo'].label != $scope.selectedFilters['feeUpTo'].labelDefault) {
                $scope.selectedFilters['feeUpTo'].label = (parseInt($scope.selectedFilters['feeUpTo'].label)).formatMoney(0, ',', '.');
            }

            if ($scope.selectedFilters.hasOwnProperty('feeFrom') && $scope.selectedFilters.hasOwnProperty('feeUpTo') && ($scope.selectedFilters['feeFrom'].label != $scope.selectedFilters['feeFrom'].labelDefault || $scope.selectedFilters['feeUpTo'].label != $scope.selectedFilters['feeUpTo'].labelDefault)) {
                $scope.dataPrice = false;
            }
        };

        /**
         * Hace una búsqueda inicial solo con los filtros de class_name y carga solo las agregaciones pasadas como parámetro, en caso de no llegar ninguna se cargan todas
         */
        $scope.initAggregation = function(reload, aggs) {
            if(aggs == undefined) {
                aggs = [];
            }
            $scope.getSearchAggregation(aggs, $scope.data.filtros, null, reload);
        };

        /**
         * Agrega un filtro
         *
         * @param filterName
         * @param filterValue
         */
        $scope.addFilter = function(filterName, filterValue, aggName, reload) {
            if (filterName instanceof Array) {
                angular.forEach(filterName, function(e, k) {
                    $scope.data.filtros[k] = e;
                    $scope.data.filtros['numFilters'] = $scope.data.filtros['numFilters'] + 1;
                });
            } else {
                if(filterValue instanceof Object) {
                    $scope.data.filtros[filterName] = filterValue.value;
                    $scope.selectedFilters[filterName].label = filterValue.label;
                    $scope.data.ignores.push(filterName);
                }else {
                    $scope.data.filtros[filterName] = filterValue;
                }
                $scope.data.filtros['numFilters'] = $scope.data.filtros['numFilters'] + 1;
            }
            $scope.data.ignores = [];
            $scope.cleanNestedFilters($scope.data.filtros, filterName);
            $scope.updateNestedLabels(filterName);
            $scope.numRequests++;

            if (!$scope.reload && (undefined != aggName || null != aggName)) {
                $scope.getAggregation(aggName, filterName, false, false, true);
            } else if(undefined == reload || reload) {
                $scope.getUrl();
            }
            if ($('#modalSelectFiltro').length > 0) {
                $('#modalSelectFiltro').removeClass('active');
            }
        };

        /**
         * Elimina un filtro
         *
         * @param filterName
         */
        $scope.removeFilter = function(filterName, aggName, reload) {
            if (filterName instanceof Array) {
                angular.forEach(filterName, function(e) {
                    delete $scope.data.filtros[e];
                    $scope.data.filtros['numFilters'] = $scope.data.filtros['numFilters'] - 1;
                });
            } else {
                $scope.selectedFilters[filterName].label = $scope.selectedFilters[filterName].labelDefault;
                delete $scope.data.filtros[filterName];
                $scope.data.filtros['numFilters'] = $scope.data.filtros['numFilters'] - 1;
            }
            $scope.updateNestedLabels(filterName);
            if(!$scope.reload && (undefined != aggName || null != aggName)) {
                $scope.getAggregation(aggName, filterName, false, false, true);
            } else if(undefined == reload || reload) {
                $scope.getUrl();
            }
            if ($('#modalSelectFiltro').length > 0) {
                $('#modalSelectFiltro').removeClass('active');
            }
        };

        /**
         * Limpia los filtros antes de la petición ajax
         */
        $scope.cleanFilters = function() {
            $log.info('Cleaning data.filtros null values');
            angular.forEach($scope.data.filtros, function(e, k) {
                if(k != 'numFilters') delete $scope.data.filtros[k]; }
            );

            angular.forEach($scope.selectedFilters, function(e, k) {
                if (e != 'numFilters') {
                    $scope.selectedFilters[k].label = $scope.selectedFilters[k].labelDefault;
                }
            });
            $scope.data.filtros['numFilters'] = 0;
        };

        /**
         * Se actualizan los labels para la pareja brand-family, sector-sector-category y sector_category-sector_category_subcategory
         * @param filterName
         */
        $scope.updateNestedLabels = function (filterName) {
            if('brand' == filterName && ($scope.data.ignores.indexOf('family') != -1 || $scope.data.filtros.hasOwnProperty('family'))) {
                $scope.selectedFilters['family'].label = $scope.selectedFilters['family'].labelDefault;
                delete $scope.data.filtros['family'];
            }
        };

        /**
         * Función que limpia los filtros que están anidados (se utiliza el ignores para excluir el filtro anidado)
         * @param filtros
         * @param filterName
         * @returns {*}
         */
        $scope.cleanNestedFilters = function(filtros, filterName) {
            if ('brand' == filterName && filtros.hasOwnProperty('family')) {
                $scope.data.ignores.push('family');
            }

            return filtros;
        };

        $scope.getAggregationCheckbox = function (aggName, filterName) {
            if ($scope.data.filtros.hasOwnProperty('destacadas')) {
                if ($scope.data.filtros['destacadas']) {
                    delete $scope.data.filtros['destacadas'];
                    $scope.data.filtros['numFilters']--;
                } else {
                    $scope.data.filtros['destacadas'] = true;
                }
            } else {
                $scope.data.filtros['destacadas'] = true;
                $scope.data.filtros['numFilters'] = $scope.data.filtros['numFilters'] + 1;
            }

            if ($scope.reload) {
                $scope.getUrl();
            } else {
                $scope.getAggregation(aggName, filterName, false, false, true);
                $scope.numRequests++;
            }
        };

        /**
         * Obtiene una agregación.
         *
         * @param aggName
         * @param filterName
         */
        $scope.getAggregation = function(aggName, filterName, cleanOwnFilter, action, reload) {
            //La primera vez como ya están cargadas las agregaciones no se vuelve a hacer otra llamada
            if((1 != $scope.numRequests || 'destacadas' == filterName) || $scope.data.filtros.hasOwnProperty(filterName)) {
                if((filterName == 'family' && !$scope.data.filtros['brand'])) {
                    return;
                }

                var buildUrls = buildUrls || false;
                if (cleanOwnFilter == undefined) {
                    cleanOwnFilter = true;
                }

                $scope.changeLoading(filterName, true);
                //$scope.cleanFilters();

                var filtros = angular.copy($scope.data.filtros);

                if (cleanOwnFilter && filtros.hasOwnProperty(filterName)) {
                    //delete filtros[filterName];
                    $scope.data.ignores.push(filterName);
                    filtros = $scope.cleanNestedFilters(filtros, filterName);
                }

                if(aggName == undefined) {
                    aggName = [];
                }
                if (!(aggName instanceof Array)) {
                    aggName = aggName.split(',');
                }

                $scope.getSearchAggregation(aggName, filtros, filterName, reload, action);
            }
        };

        $scope.getAggregationSelect = function (aggName, filterName) {
            $scope.activeFilter = filterName;
            if (typeof aggName == 'string') {
                aggName = [aggName];
            }
            $scope.getAggregation(aggName, filterName, true, 'selectFilter');
        };

        /**
         * Se encarga de obtener las agregaciones cuando un filtro especial('precio', 'kilometros', ...) cambia de valor. Se está utilizando para las subhomes
         */
        $scope.getAggregationSpecialFilters = function (reload) {
            var aggs = [];
            angular.forEach($scope.data.aggs, function (value, key) {
                aggs.push(key);
            });
            var filtros = angular.copy($scope.data.filtros);
            $scope.getSearchAggregation(aggs, filtros, undefined, reload);
            $scope.numRequests++;

        };
        //
        $scope.getSearchAggregation = function (aggName, filtros, filterName, reload, action) {
            $scope.data.router = null;
            $scope.numRequests++;
            const filtersJson = JSON.stringify(filtros)
            const aggsJson = JSON.stringify(aggName)
            const ignoresJson = JSON.stringify($scope.data.ignores)
            $http.get(Routing.generate('api_aggs_get_aggregation', { aggs: aggsJson, filters: filtersJson, ignores: ignoresJson }))
                .then(
                    function(response) {
                        if($scope.reload && reload != undefined && reload) {
                            window.location = response.data.dataUrl.url;
                        }

                        $scope.data.aggs = {};
                        $scope.data.ignores = [];

                        // if(reload != undefined && reload) {
                        //     $scope.getUrl();
                        // }

                        angular.forEach(response.data.aggs, function(e, k) {
                            $scope.data.aggs[k] = e;
                        });
                    },
                    function(response) {
                        //$log.error(response);
                    }
                )
                .then(function() {
                    if(filterName != undefined && filterName != null) {
                        $scope.changeLoading(filterName, false);
                    }
                });
        };

        /**
         * Cambia el estado de carga de un filtro
         * @param filterName
         * @param loading
         */
        $scope.changeLoading = function (filterName, loading) {
            $scope.selectedFilters[filterName].loading = loading;
        };



        /**
         * Obtiene la URL para redireccionar al usuario
         */
        $scope.getUrl = function(e) {
            if  (undefined != e) {
                e.preventDefault();
            }

            var filtros = $scope.data.filtros; //$scope.cleanNestedFilters($scope.data.filtros);
            const filtersJson = JSON.stringify(filtros)
            var url = Routing.generate('api_aggs_get_url', { filters: filtersJson });
            $http.get(url)
                .then(
                    function(response) {

                        if (response.data.hasOwnProperty('dataUrl') && response.data.dataUrl && response.data.dataUrl.hasOwnProperty('url')) {
                            $window.location.href = response.data.dataUrl.url;
                        }
                    },
                    function(response) {
                        //$log.error(response);
                    }
                );
        };

        $scope.getUrlChangingPaginaGA = function(val) {
            if (typeof pagina_ga !== "undefined") {
                pagina_ga = val;
            }
            $scope.getUrl();
        };

        $scope.getStepPrice = function() {
            return 2000;
        };

        $scope.getMaxStart = function(filterName) {
            if ($scope.data.filtros.hasOwnProperty(filterName) && $scope.data.filtros[filterName]) {
                return $scope.data.filtros[filterName];
            }

            return filterName == 'priceFrom' ? 2000 : 100;
        };

        $scope.getMaxPriceEnd = function() {
            return 100000;
        };

        $scope.getMinStart = function() {
            return 0;
        };

        $scope.getMinPriceEnd = function() {
            return 100000;
        };

        $scope.getStepFee = function() {
            return 100;
        };

        $scope.getMaxFeeEnd = function() {
            return 2000;
        };


        $scope.getMinFeeEnd = function() {
            return 2000;
        };

        $scope.changeValuePriceFee = function () {
            if ($scope.dataPrice) {
                if ($scope.selectedFilters.hasOwnProperty('feeFrom')) {
                    $scope.selectedFilters['feeFrom'].label = $scope.selectedFilters['feeFrom'].labelDefault;
                    $scope.removeSpecialilter('feeFrom');
                }
                $scope.selectedFilters['feeUpTo'].label = $scope.selectedFilters['feeUpTo'].labelDefault;
                $scope.removeSpecialilter('feeUpTo');
            } else {
                if ($scope.selectedFilters.hasOwnProperty('priceFrom')) {
                    $scope.selectedFilters['priceFrom'].label = $scope.selectedFilters['feeFrom'].labelDefault;
                    $scope.removeSpecialilter('priceFrom');
                }
                $scope.selectedFilters['priceUpTo'].label = $scope.selectedFilters['priceUpTo'].labelDefault;
                $scope.removeSpecialilter('priceUpTo');
            }
        };

        /**
         * Función que se encarga de resetear todos los filtros
         * @param reload
         */
        $scope.resetAllFilters = function(reload, e) {
            if (undefined != e) {
                e.preventDefault();
            }
            $scope.data.filtros = {};
            $scope.labels = {};
            $scope.loading = {};

            $scope.getUrl();
        };

        $scope.addSpecialFilter = function (filterName, filterValue, typeFilter, unit) {
            $scope.data.filtros[filterName] = filterValue;

            var labelInit = typeFilter + '_from';
            var labelEnd  = typeFilter + '_up_to';

            if(labelInit == filterName && $scope.data.filtros.hasOwnProperty(labelEnd) && parseInt($scope.data.filtros[labelInit]) > parseInt($scope.data.filtros[labelEnd])) {
                delete $scope.data.filtros[labelEnd];
                $scope.selectedFilters[labelEnd].label = $scope.selectedFilters[labelEnd].labelDefault;
            }

            filterValue = filterValue.formatMoney(0, ',', '.');

            /*if (undefined != unit) {
                filterValue += ' ' + unit;
            }*/

            $scope.selectedFilters[filterName].label = filterValue;
        };

        $scope.removeSpecialilter = function (filterName) {
            delete $scope.data.filtros[filterName];
            $scope.selectedFilters[filterName].label = $scope.selectedFilters[filterName].labelDefault;
            $scope.getAggregationSpecialFilters();
        };

        /**
         * Se encarga de actualizar los labels por defecto que se inicializan
         */
        $scope.updateLabelsDefault = function () {
            _.each($scope.labelsCategoria, function(e, k) {
                if($scope.selectedFilters.hasOwnProperty(k)) {
                    $scope.selectedFilters[k].label = $scope.getLabelDefault($scope.selectedFilters[k].label);
                    $scope.selectedFilters[k].labelDefault = $scope.getLabelDefault(e);
                }
            });
        };
        //
        $scope.getLabelDefault = function (labelDefault) {
            if('sin sector' == labelDefault) {
                labelDefault = $scope.getLabelDefaultCategory();
            }else if('sin categoria' == labelDefault) {
                labelDefault = $scope.getLabelDefaultSectorCategory();
            }

            return labelDefault;
        };

        $scope.containUnit = function (value, unit) {
            return value.indexOf(unit) !== -1;
        };

        $scope.showLogo = function (path, imageName) {
            return path.replace('toyota', imageName);
        };

        function ucFirst(string){
            if (string instanceof String) {
                return string.substr(0,1).toUpperCase()+string.substr(1,string.length).toLowerCase();
            }

            return string;
        }
    };

SearchCtrl.$inject = ['$scope', '$http', '$window', '$rootScope', '$log']

export default SearchCtrl
